import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { login } from '../api/api';

import Logo from '../assets/images/promotion.gif';
import ErrorImg from '../assets/error-img/error-img.gif'

const LoginForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      // Call the login function from api.js
      const result = await login(email, password);
      
      // Handle the result as needed
      console.log('Login Result:', result);
  
      // Check if login status is okay
      if (result && result.status === 'ok') {
      
        // Navigate to the next page on successful login
        navigate('/start');
      } else {
        // Handle other cases if needed
        setError('Login failed. Please check your credentials.');
      }
    } catch (error) {
      // Handle errors
      console.error('Login Error:', error);
  
      // Set the error state for displaying in the UI
      setError('Login failed. Please check your credentials.');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-lg flex flex-col items-center lg:bg-white">
        <img
          src={Logo} 
          alt="1"
          className='w-40 h-30'
        />
        <form className="w-full">
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-bold text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-bold text-gray-600">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="mt-1 p-3 w-full border rounded-md"
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        
          <div className="flex justify-between w-full mt-4">
          <button
            type="reset"
            className="bg-orange-300 text-white p-2 rounded-md hover:bg-orange-400 cursor-pointer w-full m-1"
        
            
          >
            Reset
          </button>
          <button
            type="button"
            className="bg-blue-400 text-white p-2 rounded-md hover:bg-blue-600 cursor-pointer w-full m-1"
            onClick={handleLogin}
          >
            Login
          </button>
        </div>

          {/* Display error message if there is an error */}
          {error && (
            <div className="text-red-500 text-sm mt-2 ">
               <img
          src={ErrorImg} 
          alt="1"
          className='w-40 h-30 items-center pl-9'
        />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
