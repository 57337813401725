import axios from 'axios';

const LOGINURL = process.env.REACT_APP_LOGIN_URL;
const TICKETSENTURL = process.env.REACT_APP_TICKET_SENT_URL;
const BULKTICKETSENTURL = process.env.REACT_APP_BULK_TICKET_SENT_URL;
const BULKTICKETSTATUSURL = process.env.REACT_APP_BULK_TICKET_STATUS_URL;
const PROCESSINGFILES = process.env.REACT_APP_BULK_TICKET_PROCESSING_FILES_URL;
const LASTUPLOADFILE = process.env.REACT_APP_BULK_TICKET_LAST_FILE_URL;
const TOKEN = process.env.REACT_APP_TICKET_SENT_TOKEN;



export const login = async (username, password) => {
  try {
    const response = await axios.post(LOGINURL, {
      username: username,
      password: password,
    });

    if (response.status === 200) {
      // Store the response.data in local storage
      localStorage.setItem('userData', JSON.stringify(response.data));

      // If the status is 200, return the data
      return { status: 'ok', data: response.data };
    } else {
      // If the status is not 200, return an error
      return { status: 'error', message: 'Login failed. Please check your credentials.' };
    }
  } catch (error) {
    // Handle errors
    console.error('API Error:', error);

    // Throw the error to be caught by the calling code
    throw error;
  }
};

export const sendCustomerData = async (customerData) => {
  console.log("customer data", customerData);

  try {

    const response = await axios.post(`${TICKETSENTURL}`, customerData, {
      headers: {
        Authorization: `Bearer ${TOKEN}`, // Include the token in the Authorization header
      },
    });

    if (response.status === 200) {
      // Handle success if needed
      console.log('API Response:', response.data);
      return { status:200, data: response.data };
    } else {
      // Handle error if needed
      console.error('API Error:', response);
    
    }
  } catch (error) {
    // Handle other errors
    console.error('API Error:', error);
    return error;
  }
};


export const uploadBulkTickets = async (file, userId) => {
  console.log("file", file);
  const formData = new FormData();
  formData.append('tickets', file);
  formData.append('user_id', userId);

  try {
    const response = await axios.post(BULKTICKETSENTURL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${TOKEN}`, // Include the token in the Authorization header
      },
    });

    if (response.status === 200) {
      console.log('API Response:', response.data);
      return { status: 200, data: response.data };
    } else {
    
      console.error('Unexpected API response:', response);
      return { status: response.status, message: 'Unexpected API response' };
    }
  } catch (error) {
   
    if (error.response) {
     
      console.error('API Error Response:', error.response);
      console.log('API Error Response Data:', error.response.data); // Log response.data here
      return { status: error.response.status, message: error.response.data.message || error.response.data || 'Server Error' };
    } else if (error.request) {
    
      console.error('API No Response:', error.request);
      return { status: 500, message: 'Network Error' };
    } else {
      
      console.error('API Error:', error.message);
      return { status: 500, message: error.message };
    }
  }
};



export const getFileStatues = async (fileName) => {

  try {

    const response = await axios.get(`${BULKTICKETSTATUSURL}/${fileName}`);

    if (response.status === 200) {
      // Handle success if needed
      // console.log('API Response:', response.data);
      return { status:200, data: response.data };
    } else {
      // Handle error if needed
      console.error('API Error:', response);
    
    }
  } catch (error) {
    // Handle other errors
    console.error('API Error:', error);
    return error;
  }
};


export const getProcessingJobs = async () => {

  try {

    const response = await axios.get(PROCESSINGFILES);

    if (response.status === 200) {
      // Handle success if needed
      console.log('API Response:', response.data);
      return { status:200, data: response.data };
    } else {
      // Handle error if needed
      console.error('API Error:', response);
    
    }
  } catch (error) {
    // Handle other errors
    console.error('API Error:', error);
    return error;
  }
};


export const getLastUploadFile = async () => {

  try {

    const response = await axios.get(LASTUPLOADFILE);

    if (response.status === 200) {
      // Handle success if needed
      console.log('API Response:', response.data.latestFileName);
      return { status:200, data: response.data.latestFileName};
    } else {
      // Handle error if needed
      console.error('API Error:', response);
    
    }
  } catch (error) {
    // Handle other errors
    console.error('API Error:', error);
    return error;
  }
};
