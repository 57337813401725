import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Start from "../assets/images/start.gif";

function AddNumber() {
  const navigate = useNavigate();

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);

  const Name = userData?.firstName;

  useEffect(() => {
    try {
      if (userData && userData.token) {
      } else {
        // Navigate to login if userData or token is missing
        navigate("/");
      }
    } catch (error) {
      // Handle parsing error
      console.error("Error parsing userData:", error);
      navigate("/");
    }
  }, [navigate, userData]);

  const handleSingleTicket = () => {
    navigate("/add/mobile");
  };

  const handleBulkTicket = () => {
    navigate("/add/bulk-upload");
  };

  const handleLogout = () => {
    // Remove user data from localStorage
    localStorage.removeItem("userData");

    // Navigate to the /login route
    navigate("/");
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-lg flex flex-col items-center lg:bg-white">
        <div className="flex justify-between w-full">
          <button
            className=" btn-link bg-gray-300 text-white p-2 rounded-md hover:bg-gray-400 cursor-pointer"
            onClick={handleLogout}
          >
            Log Out
          </button>
        </div>
        <h2 className="text-2xl font-bold mb-4 text-blue-400">Hi {Name}</h2>

        <div className="mb-4">
          <img
            src={Start} // Replace with actual image path
            alt="Ticket 1"
            className="  w-50 h-30"
          />
        </div>
        <div className="flex justify-between w-full">
          <button
            type="button"
            className="bg-purple-300 text-white p-2 rounded-md hover:bg-purple-400 cursor-pointer w-36"
            onClick={handleBulkTicket}
          >
            Send Bulk
          </button>
          <button
            type="button"
            className={`bg-blue-300 text-white p-2 rounded-md hover:bg-blue-600 cursor-pointer w-36`}
            onClick={handleSingleTicket}
          >
            Send Single
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddNumber;
