import './App.css';
import {
  BrowserRouter as Router, Route, Routes
} from "react-router-dom";

import { Toaster } from 'react-hot-toast';
import Login from './components/login'
import Start from './components/start'


import AddMobile from './components/add-Number'
import AddNIC from './components/add-NIC'
import AddTicket from './components/add-ticket'
import AddQTY from './components/add-QTY'
import DetailForn from './components/detailed-form'
import BulkUpload from './components/BulkUpload';




function App() {
  return (

    <div className="App">
      <Toaster position="top-right" reverseOrder={false} />
      <div className='App-header'>
      <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/start" element={<Start />} />
        <Route path="/add/mobile" element={<AddMobile />} />
        <Route path="/add/nic" element={<AddNIC />} />
        <Route path="/add/ticket" element={<AddTicket />} />
        <Route path="/add/qty" element={<AddQTY />} />
        <Route path="/show/Detail" element={<DetailForn />} />
        <Route path='/add/bulk-upload' element={<BulkUpload/>}/>
      </Routes>
    </Router>
      </div>
    </div>
  );
}

export default App;
