import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import MS from "../assets/logo/1.png";
import DN from "../assets/logo/3.png";
import GS from "../assets/logo/4.png";
import Hadahana from "../assets/logo/5.png";
import MP from "../assets/logo/6.png";
import LUCKY7 from "../assets/logo/2.png";
import AS from '../assets/logo/7.png';

const MS_ID = parseInt(process.env.REACT_APP_MAHAJANA_SAMPATHA_ID, 10);
const DN_ID = parseInt(process.env.REACT_APP_DANA_NIDANAYA_ID, 10);
const GS_ID = parseInt(process.env.REACT_APP_GOVISETHA_ID, 10);
const HADAHANA_ID = parseInt(process.env.REACT_APP_HADAHANA_ID, 10);
const MP_ID = parseInt(process.env.REACT_APP_MEGA_POWER_ID, 10);
const LUCKY7_ID = parseInt(process.env.REACT_APP_LUCKY7_ID, 10);
const AS_ID = parseInt(process.env.REACT_APP_ADA_SAMPATHA_ID, 10);

const AddTicket = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  useEffect(() => {
    try {
      if (userData && userData.token) {
      } else {
        // Navigate to login if userData or token is missing
        navigate("/");
      }
    } catch (error) {
      // Handle parsing error
      console.error("Error parsing userData:", error);
      navigate("/");
    }
  }, [navigate, userData]);

  const [selectedTicket, setSelectedTicket] = useState(null);

  useEffect(() => {
    if (location.state && location.state.ticket) {
      setSelectedTicket(location.state.ticket);
    }
  }, [location.state]);

  const handleTicketClick = (ticketId) => {
    // Handle the selection of the ticket
    setSelectedTicket(ticketId);
  };

  const handleSend = () => {
    // Validate that a ticket is chosen before navigating to the next page
    if (selectedTicket !== null) {
      navigate("/add/qty", {
        state: {
          mobile: location.state.mobile,
          nic: location.state.nic,
          ticket: selectedTicket,
        },
      });
    } else {
      // Handle the case where no ticket is chosen (e.g., show an error message)
      console.error("Please choose a ticket before proceeding.");
    }
  };

  const handleBack = () => {
    navigate("/add/nic", {
      state: {
        mobile: location.state.mobile,
        nic: location.state.nic,
      },
    });
  };
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-lg flex flex-col items-center">
        <h2 className="text-2xl font-bold mb-4 text-blue-400">Enter Ticket</h2>
        <div className="flex justify-between w-full mt-4">
          <img
            src={MS} // Replace with actual image path
            alt="Ticket 1"
            className={`cursor-pointer m-2 w-20 h-20 ${
              selectedTicket === MS_ID ? "border-4 border-blue-500" : ""
            }`}
            onClick={() => handleTicketClick(MS_ID)}
          />
          <img
            src={DN} // Replace with actual image path
            alt="Ticket 2"
            className={`cursor-pointer m-2  w-20 h-20 ${
              selectedTicket === DN_ID ? "border-4 border-blue-500" : ""
            }`}
            onClick={() => handleTicketClick(DN_ID)}
          />
          <img
            src={GS} // Replace with actual image path
            alt="Ticket 3"
            className={`cursor-pointer m-2  w-20 h-20 ${
              selectedTicket === GS_ID ? "border-4 border-blue-500" : ""
            }`}
            onClick={() => handleTicketClick(GS_ID)}
          />
        </div>

        <div className="flex justify-between  mt-4">
          <img
            src={Hadahana} // Replace with actual image path
            alt="Ticket 4"
            className={`cursor-pointer m-2  w-20 h-20 ${
              selectedTicket === HADAHANA_ID ? "border-4 border-blue-500" : ""
            }`}
            onClick={() => handleTicketClick(HADAHANA_ID)}
          />
          <img
            src={MP} // Replace with actual image path
            alt="Ticket 5"
            className={`cursor-pointer m-2  w-20 h-20 ${
              selectedTicket === MP_ID ? "border-4 border-blue-500" : ""
            }`}
            onClick={() => handleTicketClick(MP_ID)}
          />
          <img
            src={LUCKY7} // Replace with actual image path
            alt="Ticket 5"
            className={`cursor-pointer m-2  w-20 h-20 ${
              selectedTicket === LUCKY7_ID ? "border-4 border-blue-500" : ""
            }`}
            onClick={() => handleTicketClick(LUCKY7_ID)}
          />
        </div>
        <div className="flex justify-between  mt-4">
        <img
            src={AS} // Replace with actual image path
            alt="Ticket 6"
            className={`cursor-pointer m-2  w-20 h-20 ${
              selectedTicket === AS_ID ? "border-4 border-blue-500" : ""
            }`}
            onClick={() => handleTicketClick(AS_ID)}
          />
        </div>

        <div className="flex justify-between w-full mt-4 gap-1">
          <button
            type="button"
            className="bg-orange-300 text-white p-2 rounded-md hover:bg-orange-400 cursor-pointer w-full"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type="button"
            className={`bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 cursor-pointer w-full ${
              selectedTicket === null ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleSend}
            disabled={selectedTicket === null}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTicket;
