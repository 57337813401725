import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { uploadBulkTickets, getFileStatues, getProcessingJobs, getLastUploadFile } from "../api/api";
import toast from "react-hot-toast";
import '../fileUploader.css'; 

const FileUploader = () => {
  const [lastUploadFile, setLastUploadFile] = useState(null); 
  const [selectedFile, setSelectedFile] = useState(null);
  const [lastFileName, setLastFileName] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [pendingJobs, setPendingJobs] = useState([]);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getUploadFileStatus();
    gettingPendingJobs();
    gettingLastUploadFileName();
    const intervalId = setInterval(() => {
      getUploadFileStatus();
      gettingLastUploadFileName()
    }, 5000); // 5000 milliseconds = 5 seconds
    return () => clearInterval(intervalId);
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile({
        name: file.name,
        size: file.size,
        lastModified: file.lastModified,
        file: file,
      });
    }
  };

  const handleDelete = () => {
    setSelectedFile(null);
    fileInputRef.current.value = null;
  };

  console.log("hhh",lastUploadFile)


  const handleUpload = async () => {
    const userDataString = localStorage.getItem("userData");
    const userData = JSON.parse(userDataString);
    const userId = userData.id;

    if (selectedFile) {
      setUploadStatus({ message: "Uploading..." });
      try {
        const result = await uploadBulkTickets(selectedFile.file, userId);
        console.log(result);
       
        if(result.status === 400) {
          toast.error( result.message.error || result.message, { duration: 8000, className: 'toast-error' });
          console.error("Upload Error:", result.message.error || result.message);
          setUploadStatus({ 
            message: "Upload failed. " + (result.data.error || result.data.message),
          });
        } else if (result.status === 200) {
          toast.success(result.data.message, { duration: 9000, className: 'toast-success' });
          localStorage.setItem("fname", result.data.fileName);
          setUploadStatus({ message: "Upload successful." });
          getUploadFileStatus();
        } else if (result.status === 500) {
          toast.error(result.message, { duration: 8000, className: 'toast-error' });
          console.error("Upload Error:", result.message);
        }

        
        setSelectedFile(null);
        fileInputRef.current.value = null;
      } catch (error) {
        console.error("Unexpected Error:", error);
      }
    }
  };

  const getLastUploadFileName = async () => {

    try {
      const result = await getLastUploadFile();
      console.log("last upload file",result);
      setLastUploadFile(result.data);

    } catch (error) {
      console.error("Unexpected Error:", error);
    }
    
  };

  const getUploadFileStatus = async () => {
    const fname = localStorage.getItem("fname");
    let fileName = null;

    if (fname !== null) {
      fileName = fname.split(".")[0];
    }

    if (fileName !== null) {
      try {
        const result = await getFileStatues(fileName);
        setUploadStatus(result.data.jobStatus);
      } catch (error) {
        console.error(error);
        setUploadStatus({ message: "" });
      }
    } else {
      setUploadStatus({ message: "" });
    }
  };

  const gettingPendingJobs = async () => {
    try {
      const result = await getProcessingJobs();
      console.log("pending jobs",result);
      setPendingJobs(result.data.processingJobs);
    } catch (error) {
      console.error(error);
      setUploadStatus({ message: "Error fetching upload status." });
    }
  };

  const gettingLastUploadFileName = async () => {
    try {
      const result = await getLastUploadFile();
      console.log("File name",result.data);
      setLastFileName(result.data);
    } catch (error) {
      console.error(error);
      
    }
  };

  const handleBack = () => {
    navigate(-1); 
  }

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center">
        <p className="text-1xl font-bold mb-7">Upload Bulk Tickets</p>
        <div className="mb-3">
          <input
            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            type="file"
            id="formFile"
            onChange={handleFileChange}
            ref={fileInputRef}
            accept=".xlsx, .xls, "
          />
          <label
            htmlFor="formFile"
            className="form-label block text-gray-700 text-sm"
          >
            Last uploaded file name: {lastFileName ? lastFileName.split(".")[0] : "No file uploaded"}
          </label>
        </div>
        {selectedFile ? (
          <div className="selected-file mt-1 text-sm text-gray-700 text-align-left">
            {/* <p className="font-bold">Selected file: {selectedFile.name}</p> */}
            <div className="flex flex-row gap-2">
            <p className="item">
              Last modified:{" "}
              {new Date(selectedFile.lastModified).toLocaleString()}
            </p>
            <p className="item">Size: {(selectedFile.size / 1024).toFixed(2)} KB</p>
            </div>
            <div className="mt-2">
              <button
                className="px-3 py-2 bg-red-500 text-white rounded-md mr-2 hover:bg-red-600"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                onClick={handleUpload}
              >
                Upload
              </button>
            </div>
          </div>
        ) : (
          <div className="mt-3 text-sm text-gray-700 text-align-left"></div>
        )}
        {uploadStatus  && (
          <div className="upload-status">
            {uploadStatus.message && <p>{uploadStatus.message}</p>}
            {uploadStatus.fileName && (
              <div className="text-align-left">
                <div className="flex flex-row gap-2">
                  <p className="text-1xl font-bold">{uploadStatus.fileName}</p>
                  <p
                    className={`status-message ${
                      uploadStatus.status === "processing"
                        ? "processing"
                        : uploadStatus.status === "failed"
                        ? "failed"
                        : ""
                    }`}
                  >
                    {uploadStatus.status}
                  </p>
                </div>
                <p>Total: {uploadStatus.total}</p>
                <p>Processed: {uploadStatus.processed}</p>
                {uploadStatus.errorMessage.length > 0 && (
                  <div>
                    <strong>Error Messages:</strong>
                    <ul>
                      {uploadStatus.errorMessage.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {pendingJobs && pendingJobs.length > 0 ? (
          <div className="ongoing-files">
            <p className="text-1xl font-bold mt-3">Ongoing Bulk files</p>
            <div className="mt-3 text-sm text-gray-700 w-full text-left">
              {pendingJobs.length > 0 &&
                pendingJobs.map((job) => (
                  <div key={job._id}>
                    <div className="flex flex-row gap-2">
                    <p className=" text-1xl font-bold">{job.fileName}</p>
                    <p className="text-sm">Processed: {job.processed}</p>
                    <p className="text-sm">Total: {job.total}</p>
                    </div>
                </div>
                ))}
            </div>
          </div>
        ) : (
          <p className="no-ongoing-files">
           No files are currently being processed.
          </p>
        )}
         <div className="relative left-0 top-0 ml-4 mt-7">
          <button 
            type="button"
            className="bg-orange-300 hover:bg-orange-400 text-white p-2 cursor-pointer rounded-md w-20" onClick={handleBack}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
