import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function AddNumber() {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobile, setMobile] = useState("");
  const [isValid, setIsValid] = useState(false);

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  useEffect(() => {
    try {
      if (userData && userData.token) {
      } else {
        // Navigate to login if userData or token is missing
        navigate("/");
      }
    } catch (error) {
      // Handle parsing error
      console.error("Error parsing userData:", error);
      navigate("/");
    }
  }, [navigate, userData]);

  useEffect(() => {
    // Set the initial mobile value when the component mounts
    if (location.state && location.state.mobile) {
      setMobile(location.state.mobile);
    }
  }, [location.state]);

  useEffect(() => {
    // Validate the mobile number
    const isValidNumber = /^\d{10}$/.test(mobile) && mobile.startsWith("0");
    setIsValid(isValidNumber);
  }, [mobile]);

  const handleNext = () => {
    navigate("/add/nic", {
      state: {
        mobile: mobile,
      },
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-lg flex flex-col items-center lg:bg-white">
        <h2 className="text-2xl font-bold mb-4 text-blue-400">Enter Mobile</h2>
        <div className="mb-4">
          <input
            type="number"
            id="mobile"
            className="mt-1 p-2 w-full border rounded-md text-center"
            placeholder="Enter Mobile Number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>
        <div className="flex justify-between w-full gap-1">
          <button
            type="button"
            className="bg-orange-300 text-white p-2 rounded-md hover:bg-orange-400 cursor-pointer w-full"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type="button"
            className={`bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 cursor-pointer w-full ${
              !isValid ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleNext}
            disabled={!isValid}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddNumber;
