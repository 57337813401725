import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function AddNIC() {
  const navigate = useNavigate();
  const location = useLocation();
  const [NIC, setNIC] = useState();

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  useEffect(() => {
    try {
      if (userData && userData.token) {
      } else {
        // Navigate to login if userData or token is missing
        navigate("/");
      }
    } catch (error) {
      // Handle parsing error
      console.error("Error parsing userData:", error);
      navigate("/");
    }
  }, [navigate, userData]);

  useEffect(() => {
    if (location.state && location.state.nic) {
      setNIC(location.state.nic);
    }
  }, [location.state]);

  const handleNext = () => {
    navigate("/add/ticket", {
      state: {
        mobile: location.state.mobile,
        nic: NIC,
      },
    });
  };

  const handleBack = () => {
    navigate("/add/mobile", {
      state: {
        mobile: location.state.mobile,
      },
    });
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-lg flex flex-col items-center">
        <h2 className="text-2xl font-bold mb-4 text-blue-400">
          Enter NIC{" "}
          <div className="font-normal text-sm text-red-400 text-center">
            {" "}
            (Optinal)
          </div>
        </h2>
        <div className="mb-4">
          <input
            type="text"
            id="numberId"
            className="mt-1 p-2 w-full border rounded-md"
            placeholder="Enter Number ID"
            value={NIC}
            onChange={(e) => setNIC(e.target.value)}
          />
        </div>
        <div className="flex justify-between w-full gap-1">
          <button
            type="button"
            className="bg-orange-300 text-white p-2 rounded-md hover:bg-orange-400 cursor-pointer w-full"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type="button"
            className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 cursor-pointer w-full"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddNIC;
