import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Num1 from "../assets/num/1.png";
import Num2 from "../assets/num/2.png";
import Num3 from "../assets/num/3.png";
import Num4 from "../assets/num/4.png";
import Num5 from "../assets/num/5.png";

const AddQTY = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedQTY, setSelectedQTY] = useState(null);

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  useEffect(() => {
    try {
      if (userData && userData.token) {
      } else {
        // Navigate to login if userData or token is missing
        navigate("/");
      }
    } catch (error) {
      // Handle parsing error
      console.error("Error parsing userData:", error);
      navigate("/");
    }
  }, [userData, navigate]);

  useEffect(() => {
    console.log("a", location);
    if (location.state && location.state.selectedQTY) {
      setSelectedQTY(location.state.selectedQTY);
    }
  }, [location.state, location]);

  const handleTicketClick = (QTY) => {
    // Handle the selection of the ticket
    setSelectedQTY(QTY);
  };

  const handleSend = () => {
    // Handle sending the selected ticket and navigate
    if (selectedQTY !== null) {
      navigate("/show/Detail", {
        state: {
          mobile: location.state.mobile,
          nic: location.state.nic,
          ticket: location.state.ticket,
          qty: selectedQTY,
        },
      });
    } else {
      console.error("Please choose a ticket before proceeding.");
    }
  };

  const handleBack = () => {
    navigate("/add/ticket", {
      state: {
        mobile: location.state.mobile,
        nic: location.state.nic,
        ticket: location.state.ticket,
      },
    });
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-lg flex flex-col items-center">
        <h2 className="text-2xl font-bold mb-4 text-blue-400">Enter QTY</h2>
        <div className="flex justify-between w-full mt-4">
          <img
            src={Num1} // Replace with actual image path
            alt="1"
            className={`cursor-pointer  m-2 w-10 h-10 ${
              selectedQTY === 1 ? "border-4 border-blue-500" : ""
            }`}
            onClick={() => handleTicketClick(1)}
          />
          <img
            src={Num2} // Replace with actual image path
            alt="2"
            className={`cursor-pointer m-2 w-10 h-10 ${
              selectedQTY === 2 ? "border-4 border-blue-500" : ""
            }`}
            onClick={() => handleTicketClick(2)}
          />
          <img
            src={Num3} // Replace with actual image path
            alt="3"
            className={`cursor-pointer m-2 w-10 h-10 ${
              selectedQTY === 3 ? "border-4 border-blue-500" : ""
            }`}
            onClick={() => handleTicketClick(3)}
          />
        </div>

        <div className="flex justify-between  mt-4">
          <img
            src={Num4} // Replace with actual image path
            alt="4"
            className={`cursor-pointer m-2 w-10 h-10 ${
              selectedQTY === 4 ? "border-4 border-blue-500" : ""
            }`}
            onClick={() => handleTicketClick(4)}
          />
          <img
            src={Num5} // Replace with actual image path
            alt="5"
            className={`cursor-pointer m-2 w-10 h-10 ${
              selectedQTY === 5 ? "border-4 border-blue-500" : ""
            }`}
            onClick={() => handleTicketClick(5)}
          />
        </div>

        <div className="flex justify-between w-full mt-4 gap-1">
          <button
            type="button"
            className="bg-orange-300 text-white p-2 rounded-md hover:bg-orange-400 cursor-pointer w-full"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type="button"
            className={`bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 cursor-pointer w-full ${
              selectedQTY === null ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleSend}
            disabled={selectedQTY === null}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddQTY;
