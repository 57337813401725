// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-label {
  font-size: 0.78em;
}

.selected-file {
  width: 100%;
  text-align: left;
}

.item {
  font-size: 0.79em;
}

.upload-status {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #4b5563;
  width: 100%;
  text-align: left;
}

.upload-status .status-message {
  background-color: #90ee90;
  padding: 2px 5px;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  font-size: 0.9em;
  color: #006400;
}

.upload-status .status-message.processing {
  background-color: #90a1ee;
  color: #001164;
}

.upload-status .status-message.failed {
  background-color: #ffc0cb;
  color: #ff0000;
}

.ongoing-files {
  margin-top: 1rem;
  font-size: 1rem;
  width: 100%;
  text-align: left;

}

.no-ongoing-files {
  font-size: 0.75em;
}

.toast-success {
  background-color: #4caf50;
  color: white; 
}

.toast-error {
  background-color: #f44336; 
  color: white;

}
`, "",{"version":3,"sources":["webpack://./src/fileUploader.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;EACd,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,gBAAgB;;AAElB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;;AAEd","sourcesContent":[".form-label {\n  font-size: 0.78em;\n}\n\n.selected-file {\n  width: 100%;\n  text-align: left;\n}\n\n.item {\n  font-size: 0.79em;\n}\n\n.upload-status {\n  margin-top: 1rem;\n  font-size: 0.875rem;\n  color: #4b5563;\n  width: 100%;\n  text-align: left;\n}\n\n.upload-status .status-message {\n  background-color: #90ee90;\n  padding: 2px 5px;\n  border-radius: 10px;\n  width: fit-content;\n  font-weight: 500;\n  font-size: 0.9em;\n  color: #006400;\n}\n\n.upload-status .status-message.processing {\n  background-color: #90a1ee;\n  color: #001164;\n}\n\n.upload-status .status-message.failed {\n  background-color: #ffc0cb;\n  color: #ff0000;\n}\n\n.ongoing-files {\n  margin-top: 1rem;\n  font-size: 1rem;\n  width: 100%;\n  text-align: left;\n\n}\n\n.no-ongoing-files {\n  font-size: 0.75em;\n}\n\n.toast-success {\n  background-color: #4caf50;\n  color: white; \n}\n\n.toast-error {\n  background-color: #f44336; \n  color: white;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
