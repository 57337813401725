import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { sendCustomerData } from "../api/api";
import Loding from "../assets/images/loading.gif";

const MS_ID = parseInt(process.env.REACT_APP_MAHAJANA_SAMPATHA_ID, 10);
const DN_ID = parseInt(process.env.REACT_APP_DANA_NIDANAYA_ID, 10);
const GS_ID = parseInt(process.env.REACT_APP_GOVISETHA_ID, 10);
const HADAHANA_ID = parseInt(process.env.REACT_APP_HADAHANA_ID, 10);
const MP_ID = parseInt(process.env.REACT_APP_MEGA_POWER_ID, 10);
const LUCKY7_ID = parseInt(process.env.REACT_APP_LUCKY7_ID, 10);
const AS_ID = parseInt(process.env.REACT_APP_ADA_SAMPATHA_ID, 10);

const PromoName = process.env.REACT_APP_PROMOTION_NAME;

function DetailedForm() {
  const navigate = useNavigate();
  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const userId = userData.id;
  useEffect(() => {
    try {
      if (userData && userData.token) {
      } else {
        // Navigate to login if userData or token is missing
        navigate("/");
      }
    } catch (error) {
      // Handle parsing error
      console.error("Error parsing userData:", error);
      navigate("/");
    }
  }, [navigate, userData]);

  const customerData = {
    user_id: userId,
    mobile: location.state.mobile,
    nic: location.state.nic || "11111111",
    brand: location.state.ticket,
    qty: location.state.qty,
    promo_name: PromoName,
  };

  console.log(customerData);
  const getTicketName = () => {
    switch (customerData.brand) {
      case MS_ID:
        return "Mahajana Sampatha";
      case DN_ID:
        return "Dana Nidanaya";
      case GS_ID:
        return "Govisetha";
      case HADAHANA_ID:
        return "Hadahana";
      case MP_ID:
        return "Mega Power";
      case LUCKY7_ID:
        return "Lucky 7";
      case AS_ID:
        return "Ada Sampatha";
      default:
        return "";
    }
  };

  const handleNextRound = () => {
    navigate("/start");
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      // Call the asynchronous function within a try block
      const response = await sendCustomerData(customerData);


      if (response.status === 200) {
        setShowNextButton(true);
        setErrorMessage(false) 
      } else {
        setErrorMessage(response.response.data.error);
      }
    } catch (error) {
      // Handle the error and set the error message in the state
      console.error("Error sending customer data:", error);
      setErrorMessage("Something went wrong. Please try again.");
    } finally {
      setLoading(false); // Set loading back to false after the API call, whether it was successful or not
    }
  };

  const handleBack = () => {
    navigate("/start");
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-lg flex flex-col items-center w-72">
        <h2 className="text-2xl font-bold mb-4 text-blue-400">Request Summary</h2>
        <div className="mb-4">
          <div className="mb-4">
            <label
              htmlFor="mobile"
              className="block text-sm font-bold text-gray-600 text-center "
            >
              Mobile Number
            </label>
            <div className="p-3 bg-blue-200 text-center rounded-lg">
              {customerData.mobile}
            </div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="nic"
              className="block text-sm font-bold text-gray-600 text-center"
            >
              NIC
            </label>
            <div className="p-3 bg-blue-200 text-center rounded-lg">
              {location.state.nic}
            </div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="ticket"
              className="block text-sm font-bold text-gray-600 text-center"
            >
              Ticket
            </label>
            <div className="p-3 bg-blue-200 text-center rounded-lg">
              {getTicketName()}
            </div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="qty"
              className="block text-sm font-bold text-gray-600 text-center"
            >
              QTY
            </label>
            <div className="p-3 bg-blue-200 text-center rounded-lg">
              {customerData.qty}
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-4">
          <button
            type="button"
            className="bg-orange-300 text-white p-2 rounded-md hover:bg-orange-500 cursor-pointer w-full"
            onClick={handleBack}
          >
            Cancel
          </button>
          {showNextButton && (
            <button
              type="button"
              className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600 cursor-pointer w-full"
              onClick={handleNextRound}
            >
              Done
            </button>
          )}
          {!showNextButton && (
            <button
              type="button"
              className="bg-blue-400 text-white p-2 rounded-md hover:bg-blue-600 cursor-pointer w-full"
              onClick={handleNext}
            >
              {loading ? (
                <img src={Loding} alt="Loading" className="w-6 h-6" />
              ) : (
                "Proceed"
              )}
            </button>
          )}
        </div>
        {errorMessage && (
          <div className="mt-4 text-red-500 bg-red-300 text-center rounded-md">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default DetailedForm;
